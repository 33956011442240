import moment from "moment/moment";

export const selectTypes = [
  { id: 1, name: "Stocks", label: "t-detailed" },
  { id: 2, name: "Customers", label: "t-total" },
];

export const sortTypes = [
  { id: 0, name: "حسب التاريخ" },
  { id: 1, name: "حسب رقم المستند" },
  { id: 2, name: "حسب النوع" },
];
export const docTypes = [
  { id: -1, name: "الكل" },
  { id: 1, name: "فاتورة شراء " },
  { id: 2, name: "فاتورة بيع" },
  { id: 3, name: "مرتجع فاتورة شراء" },
  { id: 4, name: "مرتجع فاتورى بيع" },
  { id: 5, name: "سند قبض خارجي" },
  { id: 6, name: "سند صرف خارجي" },
  { id: 7, name: "سند قبض داخلي" },
  { id: 8, name: "سند صرف داخلي" },
  { id: 9, name: "مخزون اول مدة" },
  { id: 10, name: "قيد يومية " },
  { id: 11, name: "قيد افتتاحية" },
  { id: 12, name: "تبديل مبيعات" },
  { id: 13, name: "اقفال ورديات" },
];

export const SAdefaultValues = {
  selectType: selectTypes[0],
  dateFrom: moment(new Date()).subtract().format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
  orderType: sortTypes[0],
  docType: docTypes[0],
  branch: { id: -1, name: "الكل" },
  center: { id: -1, name: "الكل" },
  stock: { id: -1, name: "الكل" },
  account: { id: -1, name: "" },
  omla: { id: -1, name: "الكل" },
  openBalance: true,
  oldBalance: true,
};
