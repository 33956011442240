import moment from "moment";
import {
  locSto,
  /*locRem,*/ locPremiA,
  locpermiCuCo,
  locPremiScr,
  locRept,
} from "./Locstor";

const locRem = JSON.parse(window.localStorage.getItem("DataRem")) || {};

console.log("============================================");
console.log(locRem.idi);
// alert(locRem.idi+ ' in iD')
console.log("============================================");

const insh = {
  tbname: {
    More: {},
    other: {},
    Currencies: [],
    Units: [],
    AutoCurrUnits: [],
    CurrenciesToInv: [],
    countCurrBySafe: [],
    CurrenciesFav: [],
    Users: [],
    Permissions: {},
    groups: [],
    groupsSub: [],
    FavGroup: [],
    permiCurr: {},
    permis: {},
    SettInvsApp: {},
    PermissionApp: {},
    omlats: [],
    //   tot:{totSal:0,totAjel:0,totNetAndBank:0,totNetAmount:0,},
    tot: {},
    trans: [],
    SalesDetails: [],
    AccountBalance: [],

    types: [],
    Safes: [],
    Stocks: [],
    Customers: [],
    salesmen: [],
    costcenters: [],
    center: [],
    Branches: [],
    Banks: [],
    Employees: [],
    Acts: [],
    Areas: [],
    Cities: [],
    Countries: [],
    ListUsers: [],
    items: [],
    Accindex: [],
    tax_groups: [],
    Managements: [],
    //  Foundations:[],Foundation_pointss:[],tax_groupss:[],omlats:[],
    // costcenter:[],Departments:[],jobs:[],CardTypes:[],
    resData: [],

    typesFltr: [],
    SafesFltr: [],
    StocksFltr: [],

    //autos
    Management: {},
    autos: [{ id: -1, name: "" }],
  },
  autos: {
    payment: [
      { id: "1", name: "نقدي" },
      { id: "2", name: "شيك" },
      { id: "3", name: "تحويل" },
    ],
    CheckStatus: [
      { id: "1", name: "معتمد" },
      { id: "2", name: "ملغي" },
    ],
  },
  prevents: true,
  var: { custmtype: 1, typeInv: 0 },

  indx: {
    Types: 0,
    TypesFltr: 0,
    Safes: 0,
    Customers: 0,
    Salesmens: 0,
    Stocks: 0,
    bank: 0,
    Units: 0,
    Costcenters: 0,
  },

  optionPricePay: [
    { id: 1, name: "تجزئة" },
    { id: 2, name: "موزع" },
    { id: 3, name: "جملة" },
  ],

  stateChang: {
    taxperc: null,
    pricePay: true,
  },

  InfoSub: {
    id: "",
    hdshw: false,
    Price: "",
    PricePu: "",
    val1: "",
    barcode: "",
    symbol: "",
    perc: 1,
    taxperc: "",
    taxval: "",
    is_tab3_curr: 0,
    DiscPers: "",
    discount: "",
    totJust: "",
  },

  PermissionApp: locPremiA,

  Foundation: {
    id: "",
    nameA: "",
    nameE: "",
    FieldA: "",
    FieldE: "",
    bsn_no: "",
    licence_no: "",
    Address: "",
    Tel: "",
    Mobile: "",
    Fax: "",
    Email: "",
    website: "",
    Logo: "",
    Exchangeval: "",
    tax: "",
    port: "",
    tax_no: "",
    BillNotes: "",
    BillNotes1: "",
    BillNotes2: "",
    BillNotes3: "",
    Disc1_header_ar: "",
    Disc2_header_ar: "",
    Disc3_header_ar: "",
    Disc4_header_ar: "",
    Disc1_header_en: "",
    Disc2_header_en: "",
    Disc3_header_en: "",
    Disc4_header_en: "",
    Disc1_footer_ar: "",
    Disc2_footer_ar: "",
    Disc3_footer_ar: "",
    Disc4_footer_ar: "",
    Disc1_footer_en: "",
    Disc2_footer_en: "",
    Disc3_footer_en: "",
    Disc4_footer_en: "",
    MailBox: "",
    tax_ats: "",
    invval_ats: "",
    actv_elec: "",
    CustDisc: "",
    finyear_date1: "",
    finyear_date2: "",
    showimg: "",
    showvat: "",
    showdisc: "",
    showafterdisc: "",
    showtax: "",
    bank1: "",
    acc1: "",
    bank2: "",
    acc2: "",
    bank3: "",
    acc3: "",
    AddressEn: "",
    chkitemquan: "",
    msg: "",
    offer_type: "",
    VAT_Type: "",
    is_tab3: "",
    tab3val: "",
    consume_type: "",
    img: "",
    cnt: "",
    img2: "",
    contract_no: "",
    activity_name: "",
    city: "",
    branchName: "",
    sales_man: "",
    StartWorkDate: "",
    EndWorkDate: "",
    CStartWorkDate: "",
    CEndWorkDate: "",
    firstAlertDate: "",
    SecondAlertDate: "",
    ThirdAlertDate: "",
    StopWorkingDate: "",
    CStopWorkingDate: "",
    is$sys: "",
    is$$dessys: "",
    is$$$dy: "",
    server_name: "",
    point_name: "",
    status_type: "",
    computer_type: "",
    tax_no_build: "",
    tax_name_street: "",
    tax_name_district: "",
    tax_name_city: "",
    tax_name_country: "",
    tax_zip_code: "",
    tax_additional_address_no: "",
    tax_vat_registration_no: "",
  },
  Foundation_points: {
    id: "",
    nameA: "",
    nameE: "",
    FieldA: "",
    FieldE: "",
    bsn_no: "",
    licence_no: "",
    Address: "",
    Tel: "",
    Mobile: "",
    Fax: "",
    Email: "",
    website: "",
    Logo: "",
    Exchangeval: "",
    tax: "",
    port: "",
    tax_no: "",
    BillNotes: "",
    BillNotes1: "",
    BillNotes2: "",
    BillNotes3: "",
    Disc1_header_ar: "",
    Disc2_header_ar: "",
    Disc3_header_ar: "",
    Disc4_header_ar: "",
    Disc1_header_en: "",
    Disc2_header_en: "",
    Disc3_header_en: "",
    Disc4_header_en: "",
    Disc1_footer_ar: "",
    Disc2_footer_ar: "",
    Disc3_footer_ar: "",
    Disc4_footer_ar: "",
    Disc1_footer_en: "",
    Disc2_footer_en: "",
    Disc3_footer_en: "",
    Disc4_footer_en: "",
    MailBox: "",
    tax_ats: "",
    invval_ats: "",
    actv_elec: "",
    CustDisc: "",
    finyear_date1: "",
    finyear_date2: "",
    showimg: "",
    showvat: "",
    showdisc: "",
    showafterdisc: "",
    showtax: "",
    bank1: "",
    acc1: "",
    bank2: "",
    acc2: "",
    bank3: "",
    acc3: "",
    AddressEn: "",
    chkitemquan: "",
    msg: "",
    offer_type: "",
    VAT_Type: "",
    is_tab3: "",
    tab3val: "",
    consume_type: "",
    img: "",
    cnt: "",
    img2: "",
    contract_no: "",
    activity_name: "",
    city: "",
    branchName: "",
    sales_man: "",
    StartWorkDate: "",
    EndWorkDate: "",
    CStartWorkDate: "",
    CEndWorkDate: "",
    firstAlertDate: "",
    SecondAlertDate: "",
    ThirdAlertDate: "",
    StopWorkingDate: "",
    CStopWorkingDate: "",
    is$sys: "",
    is$$dessys: "",
    is$$$dy: "",
    server_name: "",
    point_name: "",
    status_type: "",
    computer_type: "",
    apps: "",
  },
  Branches: {
    id: "",
    name: "",
    Tel: "",
    Mobile: "",
    Fax: "",
    Email: "",
    Address: "",
    notes: "",
    IS_Deleted: "",
    IS_Default: "",
    IS_Active: "",
  },
  Units: {
    id: "",
    name: "",
    // IS_Default:'', IS_Active:'',
  },
  items: {
    id: "",
    symbol: "",
    nameEN: "",
    barcode: "1111",
    group_id: null,
    unit: null,
    // IS_Deleted:false,
    purch_price: "",
    sale_price: "",
    // limit:0,
    tax: 0,
    // discount:0,
    tax_group: null,
    // itemNo:null,
    min_sale_price: 0,
    // img:null,
    Distributor_price: 0,
    jomla_price: 0,
    // PakageNo:1,
    // Max_rate:0,
    // currency_type:null,
    // amount_disc:0,
    prody_date: moment(new Date()).format("YYYY-MM-DD"),
    expiry_date: moment(new Date()).format("YYYY-MM-DD"),
    is_tab3_curr: 0,
    // old_MinSalePrice:null,
    // favorite:0,
    // type:1,
    branch: locRem.branchi,
    subUnits: [],
    // post:2,
    // stop_trans:0,
    // Location:'',
  },
  groups: {
    id: "",
    name: "",
    nameEN: "",
    color: "",
    printer: "",
    group_id: "",
    parent_id: "",
    acc_branch: "",
  },
  tax_groups: {
    id: "",
    nameAR: "",
    nameEN: "",
    value: "",
    IS_Default: "",
    IS_Active: "",
    IS_DEL1: "",
    IS_Default1: "",
    IS_Active1: "",
    IS_Default111: "",
    IS_Active111: "",
    IS_Default112: "",
    IS_Active112: "",
    IS_Default113: "",
    IS_Active113: "",
    IS_Default114: "",
    IS_Active114: "",
  },
  Safes: {
    id: "",
    name: "",
    branch: "",
    status: "",
    IS_Default: false,
    notes: "",
    IS_Deleted: false,
    IS_Active: "",
    Safe_Emps: [],
  },
  Safe_Emps: {
    safe_id: null,
    emp_id: null,
  },
  Stocks: {
    id: "",
    name: "",
    branch: "",
    status: "",
    IS_Default: false,
    notes: "",
    IS_Deleted: false,
    IS_Active: "",
    Stock_Emps: [],
  },
  salesman: {
    id: "",
    name: "",
    comm: "",
    tel: "",
    email: "",
    mobile: "",
    notes: "",
  },
  Stock_Emps: {
    stock_id: null,
    emp_id: null,
  },
  Banks: {
    id: "",
    name: "",
    country: "",
    city: "",
    area: "",
    tel: "",
    mobile: "",
    notes: "",
    IS_Deleted: "",
    IS_Default: false,
    IS_Active: true,
    acc_no: "",
    acc_type: "",
  },
  costcenter: {
    id: "",
    Name: "",
    NameEN: "",
  },
  omlat: {
    id: "",
    name: "",
    exchange: "",
    IS_Default: "",
    IS_Active: "",
    symbol: "",
    NameEn: "",
    Country: "",
    FakaName: "",
    FakaNameEn: "",
    oneEqualto: "",
    TotalFaka: "",
    is_main: "",
    omla_desc: "",
  },
  Restrictions_Sub: {
    res_id: "",
    dept: "",
    credit: "",
    acc_no: "",
    notes: "",
    notes: "",
    branch: "",
    omla: "",
    omlaval: "",
    center: "",
    ref_no: "",
    RestDate: "",
    sub_type: "",
  },
  Restrictions: {
    id: "",
    date: "",
    doc_no: "",
    type: "",
    state: "",
    notes: "",
    branch: "",
    IS_Deleted: "",
    omla: "",
    omlaval: "",
    doc_no2: "",
    total_dept: "",
    total_credit: "",
    rest_type: "",
    rest_cycle_no: "",
    book_id: "",
    Center: "",
  },

  AccountBooks: {
    ID: "",
    book_id: "",
    Name: "",
    nameEN: "",
    parent_id: "",
    acc_branch: "",
  },
  Acts: {
    id: "",
    name: "",
    type: "",
  },
  Countries: { id: "", name: "", nationality: "", flag_img: "" },
  Cities: { id: "", name: "", country: "", durata_executie: "" },
  Areas: { id: "", name: "", city: "", kkk: "" },
  Managements: {
    id: "",
    name: "",
  },
  Departments: {
    id: "",
    manag_id: "",
    name: "",
  },
  jobs: {
    id: "",
    name: "",
  },
  CardTypes: {
    id: "",
    nameA: "",
    nameE: "",
  },

  // imore :{
  //   safe:-1,
  //   stock:-1,
  //   customer_name:'',
  //   cust_id:-1,
  //   salesman:-1,
  //   bank:-1,
  //   bank2:-1,
  //   center:-1,
  //   emp:-1,
  //   inv_type:2,

  //   pay_type:1
  //  },

  IdataInvs: {
    // auto Importent
    proc_id: null,

    //  proc_type:1,
    date: moment(new Date()).format("YYYY-MM-DD"),
    InvId_Return: "",
    // InvId_Return:null,
    // safe:-1,
    // stock:-1,
    safe: locSto.Safes != undefined ? locSto.Safes[0].id : -1,
    stock: locSto.Stocks != undefined ? locSto.Stocks[0].id : -1,
    inv_type: 2,
    cust_id: null,
    customer_name: "",
    // auto option
    salesman: -1,
    bank: -1,
    bank2: -1,
    center: -1,
    emp: -1,
    branch: locRem.branchi,
    sales_emp: locRem.empi,
    create_user: locRem.idi,
    update_user: null,
    minus: "", // discAmount :''
    // s sums
    tot_sale: 0,
    tot_purch: 0,
    netAmount: 0,
    netAmountPMDcn: "",
    amountBefordisc: "",
    amountAfterDisc: "",
    amountWithVat: "",
    amountOfVat: 0,
    // e sums
    Pay_mouny: "",
    Remaining: "",
    cash_part: "",
    network_part: "",
    agel_part: "",
    bank_part: "",
    emp_part: "",
    pay_type: 1,
    check_refno: "",
    refno: "",
    used_payed_window: null,
    notes: "",
    tab3Val: 0,
  },

  sandQK: {
    id: "",
    date: moment(new Date()).format("YYYY-MM-DD"),
    // cust_id:locSto.CustomersA != undefined ? locSto.CustomersA[0].id: -1,
    cust_id: "",
    val: "",
    type: "1",
    safe_bank_id: locSto.Stocks != undefined ? locSto.Stocks[0].id : -1,
    sales_emp: locSto.Employees != undefined ? locSto.Employees[0].id : -1,
    check_no: "",
    check_date: moment(new Date()).format("YYYY-MM-DD"),
    checkbank: "",
    check_state: null,
    notes: "",
    rest_id: "",
    branch: locRem.branchi,
    center: "-1",
    ref_no: "",
    end_Dept: "",
  },

  sandQD: {
    id: "",
    date: moment(new Date()).format("YYYY-MM-DD"),
    emp_person: "1",
    cust_id: "",
    val: "",
    acc_code: "",
    type: "1",
    safe_bank_id: locSto.Stocks != undefined ? locSto.Stocks[0].id : -1,
    sales_emp: locSto.Employees != undefined ? locSto.Employees[0].id : -1,
    check_no: "",
    check_date: moment(new Date()).format("YYYY-MM-DD"),
    checkbank: "",
    check_state: null,
    notes: "",
    rest_id: "",
    branch: locRem.branchi,
    center: "-1",
    ref_no: "",
  },
  sandSD: {
    id: "",
    date: moment(new Date()).format("YYYY-MM-DD"),
    emp_person: "1",
    emp_id: "",
    val: "",
    acc_code: "",
    type: "1",
    safe_bank_id: locSto.Stocks != undefined ? locSto.Stocks[0].id : -1,
    sales_emp: locSto.Employees != undefined ? locSto.Employees[0].id : -1,
    check_no: "",
    check_date: moment(new Date()).format("YYYY-MM-DD"),
    checkbank: "",
    check_state: null,
    notes: "",
    rest_id: "",
    branch: locRem.branchi,
    center: "-1",
    ref_no: "",
  },

  sandDK: {
    id: "",
    date: moment(new Date()).format("YYYY-MM-DD"),
    emp_id: locSto.Employees != undefined ? locSto.Employees[0].id : -1,
    cust_id: "",
    val: "",
    type: "1",
    safe_bank_id: locSto.Stocks != undefined ? locSto.Stocks[0].id : -1,
    sales_emp: locSto.Employees != undefined ? locSto.Employees[0].id : -1,
    check_no: "",
    check_date: moment(new Date()).format("YYYY-MM-DD"),
    checkbank: "",
    check_state: null,
    notes: "",
    rest_id: "",
    branch: locRem.branchi,
    center: "-1",
    ref_no: "",
  },
  IdataSa: { ProcType_InvProc: "02" },
  IdataSaR: { ProcType_InvProc: "12" },
  IdataSaV: { ProcType_InvProc: "03" },
  IdataPa: { ProcType_InvProc: "01" },
  IdataPaR: { ProcType_InvProc: "11" },
  IdataSf: { ProcType_InvProc: "22" },

  RptCustomer: {
    arsedah: {
      dateFrom: "2021-01-01",
      dateTo: moment(new Date()).format("YYYY-MM-DD"),
      cntr: -1,
      brnch: -1,
      accno: null,
      old: -1,
      Open: -1,
      type: 1,
    },
  },
  RptInv: [
    {
      tot_net_curr: "",
      val1: "",
      discount: "",
      new_exchange_price: "",
      symbol: "",
    },
  ],
  setting: {
    invoces: { CWVat: null, CWVtxt: "", titleHed: "", cusT: null, cusN: "" },
    customer: { type: null },
  },
  // >>>>>>>>>>> Select
  Auto: { id: -1, name: "" },
  auto: { group: "", groupSub: "" },
  AutoBank: { acc_no: -1, name: "" },
  AutoT: [
    { id: 2, name: "نقدية" },
    { id: 1, name: "آجل" },
  ],
  AutoTNQd: { id: 2, name: "نقدية" },
  AutoTAgl: { id: 1, name: "آجل" },

  AutoOp: [{ id: -1, name: "فارغ" }],
  AutoBankOp: [{ acc_no: -1, name: "فارغ" }],
  AutoTypeOp: [{ id: 2, name: "فارغ" }],

  LoadAndSucc: {
    new: false,
    save: false,
    savetmp: false,
    savePMD: false,
    delete: false,
    print: false,
  },
  Sums: { val1: 0, netAmountSub: 0 },
  Payment: { paidup: 0, stay: 0, paidupPMDcn: "", stayPMDcn: "" },
  InvDisc: { rate: "", minus: "" },
  Snack: {
    openSnak: false,
    vertical: "",
    horizontal: "",
    severit: "",
    text: "",
    time: null,
  },
  // inshBarcode = {bc:''},
  Lbl: { chashNetwork: "كاش" },
  SwitchState: {
    stayInDCurr: locPremiA.stayInDCurr,
    tableOrscanBC: false,
    cashORnetworksw: false,
  },

  // >>>>> prim
  Or: {
    postput: "toPost",
    cros: "",
    /* prop:'Tst', postjust:'', putjust:'',*/ PrAC: 0,
    i: null,
  },

  permiElm: {
    Ne: false,
    Sa: false,
    De: false,
    Se: false,
    Pr: false,
    Prv: false,
    Up: false,
    SaUp: false,
    TSP: false,
    ISPLP: false,
    USP: false,
    SIM: false,
    SVU: false,
    ASPZ: false,
    SDP: false,
    SDV: false,
    DSOI: false,
    DSOB: false,
    APDC: false,
    USD: false,
    UPD: false,
    RCNA: false,
    IPU: false,
  },

  prmiScr: {
    CWVat: false,
    Inv: false,
    Ret: false,
    chart: false,
    sal: false,
    salRet: false,
    purch: false,
    purchRet: false,
    safe: false,
    evrDay: false,
    eqfDay: false,
    activ: false,
    tafseel: false,
    safJrd: false,
    acc: false,
    custAcc: false,
    trycatch: false,
  },

  prmiScr1: [
    { screen: null },
    // {trycatch:null},
  ],

  PrmiCuCo: {
    yashml: "",
    CWVat: null,
    CWVtxt: "",
    HIB: null,
    DTSP: null,
    HPP: null,
    trycatch: null,
  },

  // >>>>> view
  UiElement: {
    stock: false,
    bank: true,
    bankPM: true,
    bankPM2: true,
    empPM: true,
    payMethod: false,
    rate: false,
    minus: false,
    /* shwo hide */
    DeleIcon: false,
    SaveIcon: false,
    ELMNTS: true,
  },

  Checked: {
    costWithVat: true,
    discMinusck: false,
    discRateck: false,
    networkck: false,
    cashck: true,

    paymentDiloagck: false,
    network_partck: false,
    cash_partck: false,
    agel_partck: false,
    bank_partck: false,
    emp_partck: false,

    autoOrStaticck: false,
  },

  con: {
    server: "",
    ipinsh: "",
    ip: "",
    changip: "",
    endDigtip: "",
    namedb: "",
    id: "",
    pass: "",
    port: ":",
    ftp: "",
    useUri: false,
    typeConnct: false,
    leavle: "",
  },

  cloud: {
    demoapp: {
      phone: "55555",
      serv: "free.oraxmobile.com",
    },
    safe: {
      phone: "0559995751",
      serv: "safeasp.oraxmobile.com",
    },
    nawi: {
      phone: "55304",
      serv: "nawiasp.oraxmobile.com",
    },
    ahmdShwqi: {
      phone: "557650060",
      serv: "oraxapp-001-site2.itempurl.com",
    },
    ramihalan: {
      phone: "540265506",
      serv: "oraxapp-001-site4.itempurl.com",
    },
    silverbox: {
      phone: "0595751",
      serv: "sondq.oraxmobile.com",
    },
    magles: {
      phone: "0552265900",
      serv: "magles.oraxmobile.com",
    },
    alhelwa: {
      phone: "0531037731",
      serv: "alhalwa.oraxmobile.com",
    },
    almostaqbl: {
      phone: "0568145331",
      serv: "almostaqbl.oraxmobile.com",
    },
    alsodasia: {
      phone: "0503586695",
      serv: "alsodasia.oraxerp.com",
    },
    amazon: {
      phone: "771999771",
      serv: "amazon.oraxerp.com",
    },
    azharseven: {
      phone: "0505999582",
      serv: "azhar7.oraxerp.com",
    },
    hozan: {
      phone: "0553614521",
      serv: "hozan.oraxerp.com",
    },
    tobala: {
      phone: "0563443882",
      serv: "tobala.oraxerp.com",
    },
    albena: {
      phone: "0506333893",
      serv: "albenaa.oraxerp.com",
    },
    trana: {
      phone: "0533123364",
      serv: "trana.oraxhotel.com",
    },
    anwar: {
      phone: "0554740417",
      serv: "anwar.oraxerp.com",
    },
    alwanAltwreed: {
      phone: "0503544021",
      serv: "alwanAltwreed.oraxhotel.com",
    },
    qahwasalesa: {
      phone: "056344",
      serv: "qahwa.oraxhotel.com",
    },
    gawansharq: {
      phone: "0505170604",
      serv: "gawanialshrq.oraxcloud.com",
    },
    oniestplas: {
      phone: "0552498575",
      serv: "oniestplas.oraxcloud.com",
    },
    mamonaahmed: {
      phone: "0559373935",
      serv: "mamona.oraxcloud.com",
    },
    dekawikaya: {
      phone: "0538063853",
      serv: "dekawikaya.oraxcloud.com",
    },
    nasmah: {
      phone: "0567959666",
      serv: "nasmah.oraxcloud.com",
    },
    aljuda: {
      phone: "0551092008",
      serv: "aljuda.oraxmobile.com",
    },
    roaimelan: {
      phone: "0200000000",
      serv: "roai3milan.oraxmobile.com",
    },
    mageda: {
      phone: "0506292616",
      serv: "mageda.oraxmobile.com",
    },
    demo2: {
      phone: "44444",
      serv: "f.oraxcloud.com",
    },
    aqwab_zahab: {
      phone: "0591999975",
      serv: "aqwab-zahab.oraxcloud.com",
    },
    mohand_said: {
      phone: "0563576405",
      serv: "mohand-said.oraxcloud.com",
    },
    ronq_tara: {
      phone: "0581114277",
      serv: "ronq-tara.oraxcloud.com",
    },
    almomares: {
      phone: "0531209662",
      serv: "almomares.oraxmobile.com",
    },
    asfar: {
      phone: "0530058002",
      serv: "asfar.oraxcloud.com",
    },
    shbakada: {
      phone: "0562272221",
      serv: "shbakada.oraxmobile.com",
    },
    shrakatdwa: {
      phone: "203040",
      serv: "other.oraxmobile.com",
    },
    holmsafar: {
      phone: "0531878220",
      serv: "other2.oraxmobile.com",
    },
    bareq_shemal: {
      phone: "0504655339",
      serv: "other7.oraxcloud.com",
    },
    sharkatalnama: {
      phone: "0533653222",
      serv: "other4.oraxcloud.com",
    },
    sharkatawsal: {
      phone: "0503075062",
      serv: "other5.oraxcloud.com",
    },
    sharkatemar: {
      phone: "0532675513",
      serv: "other6.oraxcloud.com",
    },
    alihammed: {
      phone: "0566470852",
      serv: "other8.oraxcloud.com",
    },
    rehamabdullah: {
      phone: "0599919947",
      serv: "cust5.oraxcloud.com",
    },
    mazaqtesaa: {
      phone: "0502415050",
      serv: "other10.oraxcloud.com",
    },

    alzoalnaqe: {
      phone: "0539291333",
      serv: "other4.oraxmobile.com",
    },
    alihasnalali: {
      phone: "053072283",
      serv: "other5.oraxmobile.com",
    },
    warshatfahad: {
      phone: "0533211473",
      serv: "other6.oraxmobile.com",
    },
    dalahherbd: {
      phone: "0562608038",
      serv: "other7.oraxmobile.com",
    },
    mazagzafran: {
      phone: "0564926689",
      serv: "other11.oraxcloud.com",
    },
    roqnalaselah: {
      phone: "0554443593",
      serv: "other12.oraxcloud.com",
    },
    droopalnjah: {
      phone: "0531818218",
      serv: "other13.oraxcloud.com",
    },
    enjazatwaselah: {
      phone: "0554823384",
      serv: "other14.oraxcloud.com",
    },
    haifajaza: {
      phone: "0544696742",
      serv: "other15.oraxcloud.com",
    },
    refaqalhkeer: {
      phone: "0535000595",
      serv: "other8.oraxmobile.com",
    },
    enarahqoom: {
      phone: "0569113222",
      serv: "other9.oraxmobile.com",
    },
    asasalmwasim: {
      phone: "0538733",
      serv: "other10.oraxmobile.com",
    },
    hozamialshariq: {


      phone: "0507878203",
      serv: "other.oraxcloud.com",
    },
    reefaltofeer: {
      phone: "0547268665",
      serv: "other1.oraxmobile.com",
    },
    ahmedabdulrahmin: {
      phone: "0555202185",
      serv: "other11.oraxmobile.com",
    },
    ensherahfoad: {
      phone: "0533211",
      serv: "other12.oraxmobile.com",
    },
    sanaasoltan: {
      phone: "0555366489",
      serv: "other.oraxerp.com",
    },
    hyahnadfa: {
      phone: "0592797737",
      serv: "o.oraxerp.com",
    },
    qmmnajed: {
      phone: "0599368842",
      serv: "o1.oraxerp.com",
    },
    mariambnaish: {
      phone: "0533614255",
      serv: "o3.oraxerp.com",
    },
    altofeeralnader: {
      phone: "0581843082",
      serv: "o2.oraxerp.com",
    },
    brakhmobark: {
      phone: "0549298975",
      serv: "o4.oraxerp.com",
    },
    ardarabia: {
      phone: "0538592440",
      serv: "o5.oraxerp.com",
    },
    handasaad: {
      phone: "0541591040",
      serv: "o6.oraxerp.com",
    },
    rwadaoola: {
      phone: "0547111786",
      serv: "o7.oraxerp.com",
    },
    norahali: {
      phone: "0580511154",
      serv: "o8.oraxerp.com",
    },
    fahdmusa: {
      phone: "0581787878",
      serv: "o9.oraxerp.com",
    },
    almsandalmia: {
      phone: "0595555488",
      serv: "o10.oraxerp.com",
    },
    njoomtamys: {
      phone: "0505644295",
      serv: "o11.oraxerp.com",
    },
    norahsaleh: {
      phone: "0597610892",
      serv: "o12.oraxerp.com",
    },
    eshardahab: {
      phone: "0539051372",
      serv: "o13.oraxerp.com",
    },
    sondokali: {
      phone: "0503603339",
      serv: "o14.oraxerp.com",
    },
    alarbiadeeb: {
      phone: "0581240240",
      serv: "o15.oraxerp.com",
    },
    aldwairmotkamel: {
      phone: "0551805163",
      serv: "o16.oraxerp.com",
    },
    harmalmoud: {
      phone: "0540243606",
      serv: "o17.oraxerp.com",
    },
    mazyaalalem: {
      phone: "0555908941",
      serv: "o18.oraxerp.com",
    },
    derasahar: {
      phone: "0594448592",
      serv: "o19.oraxerp.com",
    },
    adwaalmno: {
      phone: "0501642053",
      serv: "o20.oraxerp.com",
    },
    fatemaSalem: {
      phone: "0550547927",
      serv: "o21.oraxerp.com",
    },
    waqtalmezag: {
      phone: "0547749224",
      serv: "o22.oraxerp.com",
    },
    waqtalmezag2: {
      phone: "054774",
      serv: "o23.oraxerp.com",
    },
    baitaledaa: {
      phone: "0569935805",
      serv: "o24.oraxerp.com",
    },
    dalaatfal: {
      phone: "0509100961",
      serv: "o26.oraxerp.com",
    },
    alrexteqnia: {
      phone: "0503724702",
      serv: "o27.oraxerp.com",
    },
    alsaqralazraq: {
      phone: "053859",
      serv: "o28.oraxerp.com",
    },
    taqweenemar: {
      phone: "0500955556",
      serv: "o29.oraxerp.com",
    },
    masarared: {
      phone: "0595149449",
      serv: "o30.oraxerp.com",
    },
    ghalasweet: {
      phone: "0500058539",
      serv: "o31.oraxerp.com",
    },
    warqkhadmat: {
      phone: "0552793283",
      serv: "o32.oraxerp.com",
    },
    hesamohammed: {
      phone: "0597775591",
      serv: "o33.oraxerp.com",
    },
    shaishieb: {
      phone: "00563444",
      serv: "o34.oraxerp.com",
    },
    aqoolmohtrefa: {
      phone: "0558572665",
      serv: "o35.oraxerp.com",
    },
    cust_six: {
      phone: "6002600",
      serv: "o36.oraxerp.com",
    },
    brooqnokhbah: {
      phone: "0501262176",
      serv: "o37.oraxerp.com",
    },
    byootetow: {
      phone: "0500095068",
      serv: "o38.oraxerp.com",
    },
    alrabeeaqaria: {
      phone: "0597633278",
      serv: "o39.oraxerp.com",
    },
    aljamalalsharqe: {
      phone: "503603339",
      serv: "o40.oraxerp.com",
    },
    makhbazdorah: {
      phone: "0599694725",
      serv: "o41.oraxerp.com",
    },
    emdadataldaam: {
      phone: "0541032233",
      serv: "o42.oraxerp.com",
    },
    suadfesalmohammed: {
      phone: "0507719192",
      serv: "cust1.oraxcloud.com",
    },
    almostaqbalteq: {
      phone: "0508380010",
      serv: "cust2.oraxcloud.com",
    },
    madadasemah: {
      phone: "0532959946",
      serv: "cust3.oraxcloud.com",
    },
    mdarmraa: {
      phone: "0538733350",
      serv: "cust4.oraxcloud.com",
    },
    hasanmohammedali: {
      phone: "0501233197",
      serv: "cust6.oraxcloud.com",
    },
  },
};

const sto = locRem,
  Rept = locRept,
  wsto = locSto,
  premiA = locPremiA,
  permiCuCo = locpermiCuCo,
  premiScr = locPremiScr;

export { insh, sto, wsto, premiA, permiCuCo, premiScr, Rept };
