import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import { GetItemFromOptions } from "../../../services/global-services";
import { cellS, headerB } from "../../../St/comps/cStyle";

import { useStylesContainers } from "../../../St/comps/UseStyle";

const BanksTable = (props) => {
  const { data: options, banks, onShow } = props;
  const clsConts = useStylesContainers();
  const columns = [
    { title: "", field: "id", hidden: true },
    { title: "اسم البنك", field: "name" },
    { title: "الدولة", field: "CountryName" },
    { title: "المدينة", field: "CityName" },
    { title: "المنظقة", field: "AreaName" },
    { title: "تليفون", field: "tel" },
    { title: "موبايل", field: "mobile" },
    { title: "رقم الحساب", field: "acc_no" },
  ];

  function onRowClickHandle(data) {
    console.log(data);
    console.log(options);
    onShow(
      {
        ...data,
        acc_type: GetItemFromOptions(
          data?.acc_type,
          options?.auto?.AccountType
        ),
        country: GetItemFromOptions(data?.country, options.auto?.Countries),
        city: GetItemFromOptions(data?.city, options.auto?.Cities),
        area: GetItemFromOptions(data?.area, options.auto?.Areas),
      },
      "edit"
    );
  }
  return (
    <Grid item container lg={12} md={12} className={[clsConts.rootdown]}>
      <Grid
        item
        container
        xs={12}
        className={[clsConts.subDown, clsConts.subDownC]}
      >
        <Grid item xs={12}>
          <MaterialTable
            style={{ boxShadow: "none" }}
            title=""
            // variant="outlined"
            options={{
              pageSize: 7,
              //showTitle:false,
              toolbar: false,
              sorting: false,
              showTitle: false,
              paging: false,

              headerStyle: headerB,
              cellStyle: cellS,
              maxBodyHeight: "65vh",
              minBodyHeight: "65vh",
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h3
                    style={{
                      marginTop: "20vh",
                      border: "solid 0px",
                      boxShadow: "none",
                    }}
                  ></h3>
                ),
              },
            }}
            columns={columns}
            data={banks}
            onRowClick={(event, rowData) => {
              onRowClickHandle(rowData);
              event.preventDefault();
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BanksTable;
