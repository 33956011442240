import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AccountSelect from "../../../../../components/controls/accounts-select";
import { docTypes } from "../../../../Accounts/BackEnd/AccountingRPT/accountingRPT-services";

export default function StockMoveRPTForm(props) {
  const {
    rptParams,
    setRptParams,
    onChangeData,
    AutoOptions,
    onChangeAutoData,
    printHandle,
  } = props;

  return (
    <React.Fragment>
      <Grid container style={{ padding: 5 }} spacing={1}>
        <Grid item lg={4} md={6} sm={10} xs={12}>
          <AccountSelect
            accounts={AutoOptions.Accindex}
            account={rptParams.account}
            onSelect={(newValue) => {
              onChangeAutoData("account", newValue);
            }}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="datef"
            label="من تاريخ"
            type="date"
            format="YYYY-MM-DD"
            name="datef"
            value={rptParams.dateFrom}
            onChange={onChangeData("dateFrom")}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="dateT"
            label="إلى تاريخ"
            type="date"
            format="YYYY-MM-DD"
            name="datet"
            value={rptParams.dateTo}
            onChange={onChangeData("dateTo")}
          />
        </Grid>
        {/* <Grid item lg={1} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.branch}
            onChange={(event, newValue) => {
              onChangeAutoData("branch", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Branches}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label="الفرع"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={1} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.center}
            onChange={(event, newValue) => {
              onChangeAutoData("center", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.center}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label="مركز التكلفة"
                variant="outlined"
              />
            )}
          />
        </Grid> */}

        <Grid item lg={1} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.docType}
            onChange={(event, newValue) => {
              onChangeAutoData("docType", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={docTypes}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label="نوع المستند"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={1} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.omla}
            onChange={(event, newValue) => {
              onChangeAutoData("omla", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions?.Omlat}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label="العملة "
                variant="outlined"
              />
            )}
          />
        </Grid>
        {/* <Grid item lg={4} md={4} sm={6} sx={12}>
          <SelectTypesFilter {...{ rptParams, setRptParams }} />
        </Grid> */}
        {/* <Grid item lg={1} md={2} sm={2} xs={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={rptParams.openBalance}
                  onChange={(e) =>
                    onChangeAutoData("openBalance", e.target.checked)
                  }
                  name="chkVat"
                />
              }
              size="small"
              color="primary"
              fontSize="small"
              label="رصيد افتتاحي"
            />
          </FormGroup>
        </Grid> */}
        <Grid item lg={1} md={2} sm={2} xs={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={rptParams.oldBalance}
                  onChange={(e) =>
                    onChangeAutoData("oldBalance", e.target.checked)
                  }
                  name="chkVat"
                />
              }
              size="small"
              color="primary"
              fontSize="small"
              label="عرض الرصيد السابق"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
