import React from "react";
import { uri } from "../../../help/Api";
import useFetchObjectByPost from "../../../components/Hooks/useFetchObjectByPost";
import { useEffect } from "react";
import BackdropScI from "../../../services/BackdropScI";
import Banks from "./banks";
import LoadingPage from "../../../services/LoadingPage";

export default function BanksCon() {
  const tb = {
    wait: true,
    Banks: true,
    AccountType: true,
    Areas: true,
    Cities: true,
    Countries: true,
    States: true,
    parms: { tree: "sub" },
    autos: { Areas: true, Cities: true, Countries: true },
  };
  const url = uri + "Res";

  const {
    object: data,
    fetching: fetchinData,
    fetch: fetchURL,
  } = useFetchObjectByPost(url, tb);

  useEffect(() => {
    console.log("data  is", data);
  });

  if (fetchinData || !data?.auto) return <LoadingPage />;
  return (
    <Banks
      {...{
        data,
        fetchinData,
        fetchURL,
      }}
    />
  );
}
