import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { sto } from "../../../../../Context/InshData";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import { uri } from "../../../../../help/Api";
import StockMoveRPT from "./stockMove";

const defaultAutos = {
  Safes: [],
};

export default function StockMoveRPTCon() {
  const tb = {
    wait: true,
    Branches: true,
    costcenters: true,
    AccountsIndex: true,
    Stocks: true,
    omlat: true,
    stor: sto,
    parms: { filtr: false, pcode: 121 },
  };
  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb
  );
  useEffect(() => {
    console.log("loc al ", AutoOptions);
  });

  if (fetchAll || !AutoOptions?.center) return <></>;

  return (
    <StockMoveRPT
      {...{
        AutoOptions: AutoOptions?.center ? AutoOptions : defaultAutos,
        title: "حــركة الصندوق",
      }}
    />
  );
}
