import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { useStylesTF, useStylesTD } from "../../../St/comps/UseStyle";
import { useEffect } from "react";

export default function BanksForm(props) {
  const { object, data, onChange } = props;
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  // useEffect(()=>{
  //   console.log('dad', object)
  // })
  const onChangeData = (prop) => (event) => {
    onChange(event, { name: prop, value: event.target.value });
  };

  return (
    <React.Fragment>
      <Grid item lg={3} md={3} sm={4} xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id={"name"}
          label={"اسم البنك"}
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
          type="text"
          name={"name"}
          value={object.name}
          onChange={onChangeData("name")}
        />
      </Grid>
      <Grid item lg={2} md={2} sm={4} xs={6}>
        <Autocomplete // classes={clasAo}
          value={object.acc_type}
          onChange={(event, newValue) => {
            onChange(null, { name: "acc_type", value: newValue });
          }}
          id="controllable-states-dem"
          size="small"
          options={data?.auto?.AccountType}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          //style={{  }}  // disabled={}
          disabled={object.type == "main"}
          disableClearable
          renderInput={(params) => (
            <TextField
              id="valuePricePay"
              {...params}
              label={"نوع الحساب"}
              variant="outlined"
              size="small"
            />
          )}
        />
      </Grid>

      <Grid item lg={2} md={2} sm={4} xs={6}>
        <Autocomplete // classes={clasAo}
          value={object?.country}
          onChange={(event, newValue) => {
            onChange(null, { name: "country", value: newValue });
          }}
          id="controllable-states-dem"
          size="small"
          options={data?.auto?.Countries}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          //style={{  }}  // disabled={}
          disabled={object.type == "main"}
          disableClearable
          renderInput={(params) => (
            <TextField
              id="valuePricePay"
              {...params}
              label={" الدولة"}
              variant="outlined"
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item lg={2} md={2} sm={4} xs={6}>
        <Autocomplete // classes={clasAo}
          value={object?.city}
          onChange={(event, newValue) => {
            onChange(null, { name: "city", value: newValue });
          }}
          id="controllable-states-dem"
          size="small"
          options={data?.auto?.Cities}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          //style={{  }}  // disabled={}
          disabled={object.type == "main"}
          disableClearable
          renderInput={(params) => (
            <TextField
              id="valuePricePay"
              {...params}
              label={" المدينة"}
              variant="outlined"
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item lg={2} md={2} sm={4} xs={6}>
        <Autocomplete // classes={clasAo}
          value={object?.area}
          onChange={(event, newValue) => {
            onChange(null, { name: "area", value: newValue });
          }}
          id="controllable-states-dem"
          size="small"
          options={data?.auto?.Areas}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          //style={{  }}  // disabled={}
          disabled={object.type == "main"}
          disableClearable
          renderInput={(params) => (
            <TextField
              id="valuePricePay"
              {...params}
              label={" المنطقة"}
              variant="outlined"
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id={"tel"}
          label={"تليفون"}
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
          type="text"
          name={"tel"}
          value={object.tel}
          onChange={onChangeData("tel")}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id={"mobile"}
          label={"موبايل"}
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
          type="text"
          name={"mobile"}
          value={object.mobile}
          onChange={onChangeData("mobile")}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id={"notes"}
          label={"ملاحظات"}
          className={clasTF.rootSale}
          InputProps={{ className: clasTD.input }}
          type="text"
          name={"notes"}
          value={object.notes}
          onChange={onChangeData("notes")}
        />
      </Grid>

      <Grid>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                fullWidth
                checked={object.IS_Default}
                onChange={(e) =>
                  onChange(null, {
                    name: "IS_Default",
                    value: e.target.checked,
                  })
                }
                name="IS_Default"
              />
            }
            size="small"
            color="primary"
            fontSize="small"
            label="البنك الإفتراضي"
          />
        </FormGroup>
      </Grid>
    </React.Fragment>
  );
}
