import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
  Grid,
} from "@material-ui/core";

import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import BarcodeGenerator, { BarcodeGenerator04 } from "./BarcodeGenerator";
import Button from "../../../../../components/controls/Button";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useEffect } from "react";
import BarcodeGeneratorTemplate02 from "./BarcodeGeneratorTemplate02";
import { sto } from "../../../../../Context/InshData";

const pageStyle = `
@page {
    size: 40mm 25mm
};
@media all {
    .pageBreak {
        display: none
    }
};

@media print {
    .pageBreak {
        page-break-before: always;
    }
}`;

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
    border: "1px solid #000",
    backgroundColor: "#eee",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

class ComponentToPrint extends React.Component {
  state = {
    pwca: true,
  };
  constructor() {
    super();
  }

  render() {
    const styleTyg = {
      // backgroundColor: '#fff',
      color: "#000",
      padding: "0.2mm",
      maxWidth: "40mm",
    };

    return (
      <Typography component="div" style={styleTyg}>
        <BarcodeGenerator
          barcode={this.props.data.barcode}
          itemName={this.props.data.symbol}
          price={this.props.data.sale_price}
        />
      </Typography>
    );
  }
}

class ComponentToPrintTemplate02 extends React.Component {
  state = {
    pwca: true,
  };

  render() {
    const styleTyg = {
      // backgroundColor: '#fff',
      color: "#000",
      padding: "0.1mm",
      maxWidth: "40mm",
    };

    return (
      <Typography component="div" style={styleTyg}>
        <BarcodeGeneratorTemplate02
          barcode={this.props.data.barcode}
          itemName={this.props.data.symbol}
          price={this.props.data.sale_price}
          expiry_date={this.props.data.expiry_date}
          prody_date={this.props.data.prody_date}
        />
      </Typography>
    );
  }
}
class ComponentToPrintTemplate04 extends React.Component {
  state = {
    pwca: true,
  };

  render() {
    const styleTyg = {
      // backgroundColor: '#fff',
      color: "#000",
      padding: "0.1mm",
      maxWidth: "40mm",
    };

    return (
      <Typography component="div" style={styleTyg}>
        <BarcodeGenerator04
          barcode={this.props.data.barcode}
          itemName={this.props.data.symbol}
          price={this.props.data.sale_price}
          expiry_date={this.props.data.expiry_date}
          prody_date={this.props.data.prody_date}
        />
      </Typography>
    );
  }
}
export default function BarcodeDialog(props) {
  const { open, data, setOpen } = props;
  const componentRef = useRef();
  const componentRef02 = useRef();
  const componentRef04 = useRef();
  const classes = useStyles();

  useEffect(() => {
    console.log("the sto is", data);
  });
  return (
    <Dialog open={open} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h5">{"شاشة طباعة الباركود"}</Typography>
        <br />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogContent}>
          <ComponentToPrintTemplate02 ref={componentRef02} {...{ data }} />
        </div>
        <ReactToPrint
          trigger={() => (
            <Button
              text="طباعة"
              size="small"
              color="secondary"
              variant="outlined"
            />
          )}
          content={() => componentRef02.current}
          pageStyle={pageStyle}
        />
        <div className={classes.dialogContent}>
          <ComponentToPrint ref={componentRef} {...{ data }} />
        </div>
        <ReactToPrint
          trigger={() => (
            <Button
              text="طباعة"
              size="small"
              color="secondary"
              variant="outlined"
            />
          )}
          content={() => componentRef.current}
          pageStyle={pageStyle}
        />
        <div className={classes.dialogContent}>
          <ComponentToPrintTemplate02 ref={componentRef02} {...{ data }} />
        </div>
        <ReactToPrint
          trigger={() => (
            <Button
              text="طباعة"
              size="small"
              color="secondary"
              variant="outlined"
            />
          )}
          content={() => componentRef02.current}
          pageStyle={pageStyle}
        />
        <div className={classes.dialogContent}>
          <ComponentToPrintTemplate04 ref={componentRef04} {...{ data }} />
        </div>
        <ReactToPrint
          trigger={() => (
            <Button
              text="طباعة"
              size="small"
              color="secondary"
              variant="outlined"
            />
          )}
          content={() => componentRef04.current}
          pageStyle={pageStyle}
        />
        {/* <Grid container xs={12} spacing={2}>
          <Grid item xs={4}>
            <div className={classes.dialogContent}>
              <ComponentToPrint ref={componentRef} {...{ data }} />
            </div>
            <ReactToPrint
              trigger={() => (
                <Button
                  text="طباعة"
                  size="small"
                  color="secondary"
                  variant="outlined"
                />
              )}
              content={() => componentRef.current}
              pageStyle={pageStyle}
            />
          </Grid>
          <Grid item xs={4}>
            <div className={classes.dialogContent}>
              <ComponentToPrintTemplate02 ref={componentRef02} {...{ data }} />
            </div>
            <ReactToPrint
              trigger={() => (
                <Button
                  text="طباعة"
                  size="small"
                  color="secondary"
                  variant="outlined"
                />
              )}
              content={() => componentRef02.current}
              pageStyle={pageStyle}
            />
          </Grid>
          <Grid item xs={4}>
            <div className={classes.dialogContent}>
              <ComponentToPrintTemplate02 ref={componentRef02} {...{ data }} />
            </div>
          </Grid>
        </Grid> */}
      </DialogContent>
      {/* <DialogContent className={classes.dialogContent}></DialogContent> */}
      <DialogActions className={classes.dialogAction}>
        <Button text="إغلاق" color="secondary" onClick={() => setOpen(false)} />
        <ReactToPrint
          trigger={() => <Button text="طباعة" color="secondary" />}
          content={() => componentRef.current}
        />
      </DialogActions>
    </Dialog>
  );
}
