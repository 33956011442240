import React, { useEffect, useState } from "react";
import {
  Button,
  colors,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import StockMoveRPTForm from "./stockMove-form";

import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import { useHistory } from "react-router-dom";

import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import {
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../../St/Reports/UseStyle";
import BalanceCard from "../../../../../components/BalanceCard";
import moment from "moment";
import AccountingRPTTable from "../../../../Accounts/BackEnd/AccountingRPT/accountingRPT-table";
import { insh as inshst } from "../../../../../Context/InshSt";
import { SAdefaultValues } from "../../../../Accounts/BackEnd/AccountingRPT/accountingRPT-services";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#faf1f1",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },

  headerTitle: {
    backgroundColor: "#ffffff",
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },

  blCard: {
    backgroundColor: "#fff",
    borderColor: "#319835",
  },
}));

export default function StockMoveRPT(props) {
  const { AutoOptions } = props;
  const [rptParams, setRptParams] = React.useState({
    ...SAdefaultValues,
    account: AutoOptions?.Accindex[0],
  });
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);

  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `AccountReports/rptAccBalance?acc_no=${rptParams.account.id}
    &&dateFrom=${rptParams.dateFrom}
    &&dateTo=${rptParams.dateTo}
    &&branch=${rptParams.branch.id}
    &&center=${rptParams.center?.id}
    &&openBalance=${false}
    &&oldBalance=${rptParams.oldBalance}
    &&orderType=${rptParams.orderType.id}
    &&docType=${rptParams.docType.id}
    &&omla=${rptParams.omla.id}`,
      dependencyArray: [],
    },
  });

  useEffect(() => {
    console.log(" data ", data);
  });
  const his = useHistory();
  const toBack = () => {
    his.push({ pathname: "/" });
  };
  const printHandle = () => {
    const info = {
      data: data?.AccountBalance,
      tot: data?.totals,
      from: "الحساب",
      title: "حركة الصندوق",
      auto: {},
      account: rptParams.account,
      fDate: rptParams.dateFrom,
      tDate: rptParams.dateTo,
      nDate: moment(new Date()).format("YYYY-MM-DD"),
      ElmntShow: true,
      //totsp:rpt.totSelcPeriod,balncsp:rpt.balncSelcPeriod
    };
    localStorage.setItem("iInfos", JSON.stringify(info));
    his.push({ pathname: "/ReviewAccount" });
    window.location.reload(false);
  };

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography size="small" className={classes.headerTitle}>
          {t(props.title)}
        </Typography>
      </Grid>
      <Typography component="div" style={{ direction: "rtl" }}>
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: inshst.colors.sal,
            borderBottomWidth: "10px",
            borderBottomStyle: "solid",
          }}
        >
          <StockMoveRPTForm
            {...{
              AutoOptions,
              rptParams,
              setRptParams,
              onChangeData,
              onChangeAutoData,
              printHandle,
            }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={{ ...inshst.sal.iconBtn, borderRadius: 0 }}
                className={[clasui.button]}
                onClick={() => fetchURL()}
              >
                {t("forms:view")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={{ ...inshst.sal.iconBtn, borderRadius: 0 }}
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={{ ...inshst.sal.iconBtn, borderRadius: 0 }}
                onClick={toBack}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth={true} style={{ padding: "5px" }}>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            style={{ paddingTop: "10px" }}
          >
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <BalanceCard
                {...{
                  title: "تـسديد",
                  dept: data?.totals?.totBlncDept,
                  className: classes.blCard,
                  credit: data?.totals?.totBlncCredit,
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <BalanceCard
                {...{
                  title: "الدين المتبقي",
                  className: classes.blCard,
                  dept: data?.totals?.totDept,
                  credit: data?.totals?.totCredit,
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            // paddingTop:'5px',
          }}
        >
          <Grid container>
            {fetching ? (
              <Paper>
                {"يرجى الانتظار قليلا حتى يتم جلب البيانات ..."}
                <LinearProgress />
              </Paper>
            ) : (
              <AccountingRPTTable
                {...{
                  data: data?.AccountBalance ? data.AccountBalance : [],
                  color: inshst.colors.sal,
                }}
              />
            )}
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
