import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

import Container from "@material-ui/core/Container";
import { useStylesContainers, useStylesHed } from "../../../St/comps/UseStyle";
import BanksForm from "./banks-form";
import BanksTable from "./banks-table";
import toast, { Toaster } from "react-hot-toast";
import { insh } from "../../../Context/InshData";
import basicDataAPI from "../../../api/basicData";
import useFormDataCRUD from "../../../components/Hooks/useEDSCURD";
import BasiceAppBar from "../components/basic-appBar";

export default function Banks(props) {
  const { data, fetchURL } = props;
  const defaultValues = {
    ...insh.Banks,
    acc_type: data?.auto?.AccountType[0],
    // status: data?.auto?.States[0],
  };

  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = useFormDataCRUD(
    basicDataAPI.create,
    basicDataAPI.update,
    basicDataAPI.removeBank,
    undefined,
    defaultValues,
    null,
    fetchURL
  );
  useEffect(() => {
    console.log("secound item", object);
  });

  const clsConts = useStylesContainers();
  const clashd = useStylesHed();
  const onSubmitHandle = (data) => {
    if (data.name == "") {
      toast.error("ادخل اسم البنك");
    } else if (data.countery == "" || data?.city == "" || data?.area == "") {
      toast.error("حدد الدولة والمدينة والمنطقة ");
    } else {
      const tb = {
        banks: {
          ...data,
          acc_type: data?.acc_type?.id,
          country: data?.country?.id,
          area: data?.area?.id,
          city: data?.city?.id,
        },
        // Stock_Emps: Stock_Emps,
        tbstate: { Banks: true, parms: { tname: "Banks" } },
      };

      if (formType == "edit") update(tb);
      else submit(tb);
    }
  };

  const styheader = {
    textAlign: "center",
    backgroundColor: "#fff",
    height: "100vh",
    direction: "rtl",
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Container maxWidth="xl">
        <Toaster />

        <Typography component="div" style={styheader}>
          <Grid item container>
            <Grid item xs={12}>
              <Chip
                size="small"
                className={clashd.ChipHdSale}
                label={" البنـــوك"}
                color="primary"
              />
            </Grid>
            <Grid container xs={12}>
              <Grid
                item
                container
                lg={12}
                md={12}
                className={[clsConts.rootup]}
              >
                <Grid
                  item
                  container
                  spacing={1}
                  xs={12}
                  className={[clsConts.subUp, clsConts.subUpC]}
                >
                  <BanksForm
                    {...{
                      ...props,
                      onChange,
                      submit,
                      update,
                      remove,
                      submitting,
                      errorMessage,
                      onShow,
                      onEnd,
                      object,
                      formType,
                    }}
                  />
                </Grid>
              </Grid>
              <BanksTable
                {...{ ...props, onShow, banks: props.data?.table?.resData }}
              />
            </Grid>
          </Grid>
          <BasiceAppBar
            {...{
              ...props,
              onSubmitHandle,
              onChange,
              defaultValues,
              submit,
              update,
              remove,
              submitting,
              errorMessage,
              onShow,
              onEnd,
              object,
              formType,
            }}
          />
        </Typography>
      </Container>
    </React.Fragment>
  );
}
