import React, { useState, useEffect } from "react";

import { SAdefaultValues } from "../../../../../services/currencySalesProfit-services";
import {
  Button,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import SelectTypesFilter from "./CurrencySalesProfit-typeFilter";
import CurrencySalesProfitForm from "./CurrencySalesProfit-form";
import CurrencySalesProfitTable from "./CurrencySalesProfit-table";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { insh as inshst } from "../../../../../Context/InshSt";

import {
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: colors.green[50],
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },
  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
}));

export default function CurrencySalesProfit(props) {
  const { AutoOptions } = props;
  const [rptParams, setRptParams] = React.useState(SAdefaultValues);
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);
  const his = useHistory();

  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `RptSales/CurrencySalesProfit?currency=${rptParams.currency.id}
    &&dateFrom=${rptParams?.dateFrom}
    &&dateTo=${rptParams?.dateTo}
    &&safe=${rptParams.safe?.id}
    &&chk_withvat=${rptParams?.chk_withvat}
    &&type_Cost=${rptParams.type_Cost?.id}`,
      dependencyArray: [],
    },
  });

  const printHandle = () => {
    const info = {
      ...rptParams,
      data: data?.CurrencySalesProfit,
      totals: data?.totals,
      no: 2,
      title: "صافي مبيعات وارباح الاصناف",
    };

    console.log("the name is", info);
    his.push({ pathname: "/SalesManPrint", state: info });

    // window.location.reload(false);
  };
  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography size="small" className={classes.headerTitle}>
          {t("translation:sale-profit")}
        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            borderBottomColor: colors.green[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <SelectTypesFilter {...{ rptParams, setRptParams }} />
          <CurrencySalesProfitForm
            {...{ AutoOptions, rptParams, onChangeData, onChangeAutoData }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={(inshst.sal.iconBtn, { borderRadius: 0 })}
                className={[clasui.button]}
                onClick={() => fetchURL()}
              >
                {t("forms:view")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={(inshst.sal.iconBtn, { borderRadius: 0 })}
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={(inshst.sal.iconBtn, { borderRadius: 0 })}
                onClick={() => his.goBack()}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("forms:quantity-sold"),
                  value: data?.totals?.qtyTotal,
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("total-sales-value"),
                  value: data?.totals?.totNetTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("total-sales-cost"),
                  value: data?.totals?.costTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("profit"),
                  value: data?.totals?.profitTotal.toFixed(2),
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.green[300]} />
              {!fetching && (
                <CurrencySalesProfitTable
                  {...{
                    data: data?.CurrencySalesProfit
                      ? data.CurrencySalesProfit
                      : [],
                    title: rptParams.type_Cost.label,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
